import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalsCard = _resolveComponent("KTModalsCard")
  const _component_KTShareAndEarnModal = _resolveComponent("KTShareAndEarnModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_KTModalsCard, {
      title: "Share & Earn Modal Example",
      description: "Click on the below buttons to launch <br/>a share & earn example.",
      image: "media/illustrations/coding.png",
      "button-text": "Share & Earn",
      "modal-id": "kt_modal_share_earn"
    }),
    _createVNode(_component_KTShareAndEarnModal)
  ], 64))
}